import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';

import './assets/css/main.css';

import getSettings from "./static/settings";
Vue.prototype.$globalSettings = getSettings();

import globalHelpers from "./static/helpers";
Vue.prototype.$globalHelpers = globalHelpers;

Vue.config.productionTip = false;
axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + globalHelpers.getUserStoreValue('token');
    return config;
});
Vue.prototype.$axios = axios;


Vue.prototype.$store = store;

import getEnums from "./static/enums";
Vue.prototype.$globalEnums = getEnums();



import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, Vue.prototype.$globalSettings.toast);

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading, Vue.prototype.$globalSettings.loading);

globalHelpers.userInit().then(() => {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app');
});
