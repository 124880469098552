const enums = {
    paymentTypes: {
        'Receive': 1,
        'Pay': 2,
        'Reconciliation': 3,
        'JournalEntry': 4,
        'Sale': 5,
        'Purchase': 6,
        'SalesReturn': 7,
        'PurchaseReturn': 8,
    },
    formTypes: {
        'Sale': 1,
        'Purchase': 2,
    },
    saleTypes: {
        'Quote': 1,
        'Order': 2,
        'Invoice': 3,
        'DeliveryNote': 4,
        'Return': 5
    },
    purchaseTypes: {
        'Quote': 1,
        'Order': 2,
        'Invoice': 3,
        'ReceivedNote': 4,
        'Return': 5
    },
    obEntityTypes: {
        'Account': 1,
        'Product': 2,
        'Customer': 3,
        'Supplier': 4
    }


};
export default function getEnums() {
    return enums;
}