export default {
    namespaced: true,

    state: {
        authenticated: false,
        token: null,
        user_role_id: null,
        user_role_name: null,
        username: null,
    },

    getters: {
        authenticated (state) {
            return state.authenticated
        },
        token (state) {
            return state.token
        },
        user_role_id (state) {
            return state.user_role_id
        },
        user_role_name (state) {
            return state.user_role_name
        },
        username (state) {
            return state.username
        },
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_TOKEN (state, value) {
            state.token = value
        },
        SET_USER_ROLE_ID (state, value) {
            state.user_role_id = value
        },
        SET_USER_ROLE_NAME (state, value) {
            state.user_role_name = value
        },
        SET_USERNAME_ID (state, value) {
            state.username = value
        },
    },

    actions: {
        setToken ({ commit }, {token}) {
            commit('SET_TOKEN', token);
        },
        userInit ({ commit }, {authenticated, user_role_id, user_role_name, username}) {
            commit('SET_AUTHENTICATED', authenticated);
            commit('SET_USER_ROLE_ID', user_role_id);
            commit('SET_USER_ROLE_NAME', user_role_name);
            commit('SET_USERNAME_ID', username);
        }
    }
}