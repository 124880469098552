const static_settings = {
    app_name: 'EPB EXPORT DB',
    app_icon: '/images/logo.png',
    api: {
        base_url: 'http://localhost:3301/api/',
        //base_url: 'https://exportdbapi.epb-exporttracker.gov.bd/api/',
        endpoints: {
            authentication: {
                login: 'Authentication/Post',
                logout: 'Authentication/Get',
            },
            dashboard:{
                dashboardData: 'Data/DashboardData',
            },
            report:{
                tableData: 'Data/TableData',
                sbDuplicate: 'Data/SBDuplicate',
            },
            action:{
                deleteData: 'Data/DeleteData',
                resyncExp: 'Data/ResyncExpAsync',
            },
            user:{
                userInit: 'Authentication/UserInit',
            },
        },
    },
    loading: { color: '#FF5722', canCancel: false, loader: 'spinner', width: 64, height: 64, opacity: 0.1, zIndex: 999 },
    toast: { position: 'top-right', duration: 3000 },


};
export default function getSettings() {
    return static_settings;
}