<template>
    <v-app>
        <v-main class="background">
            <v-container fill-height fluid>
                <v-row no-gutters>
                    <v-col sm="4" class="login-bg" offset-xl="4" offset-lg="4" offset-md="4">
                        <v-img contain height="100" src="/images/logo.png" class="mx-auto my-12"></v-img>
                        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
                            <template slot="progress">
                                <v-progress-linear
                                        color="teal darken-4"
                                        height="10"
                                        indeterminate
                                ></v-progress-linear>
                            </template>

                            <!--<v-img
                                    height="250"
                                    src="/images/loginbg.jpg"
                            ></v-img>-->

                            <div class="text-center pa-5">{{ $globalSettings.app_name }}</div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-text>
                                <validation-observer ref="observer" v-slot="{ invalid }">
                                    <form @submit.prevent="login">
                                        <validation-provider v-slot="{ errors }" name="Username" rules="required">
                                            <v-text-field v-model="formData.username" :error-messages="errors" label="Username" :disabled="loading" autofocus required></v-text-field>
                                        </validation-provider>
                                        <validation-provider v-slot="{ errors }" name="Password" rules="required">
                                            <v-text-field v-model="formData.password" type="password" :error-messages="errors" label="Password" :disabled="loading" required></v-text-field>
                                        </validation-provider>

                                        <v-row no-gutters>
                                            <v-col sm="6">
                                            </v-col>
                                            <v-col sm="6">
                                                <v-card-actions class="d-flex flex-row-reverse">
                                                    <v-btn type="submit" :disabled="invalid || loading">Login</v-btn>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                                    </form>
                                </validation-observer>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import { required } from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

    //setInteractionMode('eager');
    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });

    export default {
        name: "Login_view",
        data: () => ({
            loading: false,

            formData: {
                username: null,
                password: null
            }
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        methods: {
            login: async function () {
                this.loading = true;
                if (await this.$globalHelpers.signIn({username: this.formData.username, password: this.formData.password})) {
                    if (await this.$globalHelpers.userInit()) {
                        await this.$router.push({name: 'App.Dashboard'});
                    } else {
                        Vue.$toast.open({message: 'You are not authorized!', type: 'error'});
                    }
                    this.loading = false;
                } else {
                    Vue.$toast.open({message: 'Login failed! Please try again.', type: 'error'});
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped>
    .background{
        background-image: url('../../assets/images/loginbg.jpg') ;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /*background: #00695C*/
    }
    .login-bg{

    }
</style>