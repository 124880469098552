import Vue from 'vue'
import VueRouter from 'vue-router'
import Login_view from "../views/auth/Login_view";
import Layout_view from "../views/app/Layout_view";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login_view
  },
  {
    path: '/app',
    name: 'App',
    component: Layout_view,
    meta: {isPrivate: true},
    children: [
        {
          path: 'dashboard',
          name: 'App.Dashboard',
          meta: {isPrivate: true},
          component: () => import('../views/app/Dashboard_view')
        },
        {
          path: 'exps',
          name: 'App.EXPs',
          meta: {isPrivate: true},
          component: () => import('../views/app/exp/EXPs_view')
        },
        {
          path: 'shipping-bills',
          name: 'App.ShippingBills',
          meta: {isPrivate: true},
          component: () => import('../views/app/sb/ShippingBills_view')
        },
        {
          path: 'shipping-bills/duplicates',
          name: 'App.ShippingBills.Duplicates',
          meta: {isPrivate: true},
          component: () => import('../views/app/sb/ShippingBillsDuplicates_view')
        },
    ]
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const isPrivate = to.meta.isPrivate;
  let store = Vue.prototype.$store;
  if (isPrivate) {
    if (store.getters["auth/authenticated"] && store.getters["auth/token"] != null) {
      return next();
    } else {
      return next({name: 'Login'});
    }
  } else {
    return next();
  }
});

export default router
