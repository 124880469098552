import Vue from 'vue'
//import Vue.prototype.$store from '../Vue.prototype.$store/index';
import axios from "axios";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

//authentication
async function signIn(credentials) {
    return await axios.post(Vue.prototype.$globalSettings.api.endpoints.authentication.login, credentials)
        .then((response) => {
            if(response.data.Status === 'success'){
                Vue.prototype.$store.dispatch('auth/setToken', {token: response.data.Token});
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.log(error);
            return false;
        })
}

async function userInit() {
    let token = getUserStoreValue('token');

    return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.userInit, null, {
        headers: {Authorization: `Bearer ${token}`}
    })
        .then((response) => {
            if(response.data.Status === 'success'){
                Vue.prototype.$store.dispatch('auth/userInit', {authenticated: true, user_role_id: response.data.RoleId, user_role_name: response.data.RoleName, username: response.data.UserName});
                return true;
            }else{
                Vue.prototype.$store.dispatch('auth/userInit', {authenticated: false, user_role_id: null, user_role_name: null, username: null});
                return false;
            }
        })
        .catch(() => {
            //console.log(error);
            Vue.prototype.$store.dispatch('auth/userInit', {authenticated: false, user_role_id: null, user_role_name: null, username: null});
            return false;
        })
}

//user
function getUserStoreValue(key) {
    return Vue.prototype.$store.getters['auth/'+key];
}

//utility
function getDateToday() {
    return dayjs().format('YYYY-MM-DD');
}

function getFormattedDate(date) {
    return dayjs(date, "YYYY-MM-DD H:mm:ss").format('DD/MM/YYYY');
}
function getDateOfFormat(date, format) {
    return dayjs(date, "YYYY-MM-DD H:mm:ss").format(format);
}

//print
function printDiv(elementId) {
    // Get HTML to print from element
    const prtHtml = document.getElementById(elementId).innerHTML;

    // Get all stylesheets HTML
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }

    // Open the print window
    const WinPrint = window.open('', '', 'left=0,top=0,width=1280,height=900,toolbar=0,scrollbars=0,status=0');

    WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                  <title>${this.title + ' - ' + this.id}</title>
                    ${stylesHtml}
                  </head>
                  <body style="font-size: 16px;">
                    ${prtHtml}
                  </body>
                </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    setTimeout(function(){WinPrint.close();}, 200);
}

//comparing
function isEmptyOrUndefined(value) {
    if(!value){
        return true;
    }
    if(value == undefined){
        return true;
    }
    if(value === ''){
        return true;
    }
    return false;
}

//error handling
function processHttpErrorsToast(error) {
    if(error.response.status === 422){
        for(let err in error.response.data.errors){
            if (Object.prototype.hasOwnProperty.call(error.response.data.errors, err)) {
                Vue.$toast.open({message: error.response.data.errors[err][0], type: 'error'});
            }
        }
    }else{
        Vue.$toast.open({ message: error,  type: 'error'});
    }
}

//expression manipulations
function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}


export default { signIn, userInit, getUserStoreValue, getDateToday, getFormattedDate, getDateOfFormat, printDiv, isEmptyOrUndefined, processHttpErrorsToast, humanize }