<template>
    <v-app>

        <v-navigation-drawer app :clipped="clipped" v-model="drawer" enable-resize-watcher class="secondary-bg">
            <v-list-item class="accent-bg white--text">
                <v-list-item-content>
                    <v-row no-gutters class="pt-2 pb-1">
                        <v-col sm="2">
                            <v-img contain height="30" :src="$globalSettings.app_icon"></v-img>
                        </v-col>
                        <v-col sm="10" class="text-center pt-1 hidden-xs-only">
                            <span class="v-list-item--highlighted">{{ $globalSettings.app_name }}</span>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="pt-1 pb-1">
                <v-list-item-content class="white--text">
                    <v-row no-gutters class="pt-2 pb-1">
                        <v-col sm="2">
                            <v-img contain height="30" src="/images/user.png"></v-img>
                        </v-col>
                        <v-col sm="10" class="pl-3 hidden-xs-only">
                            <div class="text-uppercase">
                                {{username}}
                            </div>
                            <div class="font-italic text-sm-caption">
                                {{roleName}}
                            </div>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list dense nav class="">
                <v-list-item v-for="item in menuItems" :key="item.title" link class="theme--dark" :class="item.routeName === $route.name ? 'accent-bg' : ''" v-on:click="navigate(item.routeName)">
                    <v-list-item-icon>
                        <v-icon class="white--text">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar fixed app class="accent-bg white--text" :clipped-left="clipped">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>

            <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item v-on:click="logout()">
                        <v-list-item-title><v-icon>mdi-logout</v-icon> Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid>

                <!-- If using vue-router -->
                <v-slide-x-transition mode="out-in">
                    <keep-alive include="Dashboard_view">
                        <router-view></router-view>
                    </keep-alive>
                </v-slide-x-transition>
            </v-container>
        </v-main>

        <v-footer app>
            <!-- -->
        </v-footer>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    export default {
        name: "Layout_view",
        data: function () {
            return{
                drawer: true,
                clipped: false,
                username: this.$globalHelpers.getUserStoreValue('username'),
                roleName: this.$globalHelpers.getUserStoreValue('user_role_name'),
                menuItems: [
                    { routeName: 'App.Dashboard', routeParams: {}, label: 'Dashboard', icon: 'mdi-view-dashboard', isDefault: true, adminOnly: false},
                    { routeName: 'App.EXPs', routeParams: {}, label: 'EXPs', icon: 'mdi-alpha-e-circle', isDefault: true, adminOnly: false},
                    { routeName: 'App.ShippingBills', routeParams: {}, label: 'Shipping Bills', icon: 'mdi-alpha-s-circle', isDefault: true, adminOnly: false},
                ]
            }
        },
        methods:{
            navigate: async function (routeName) {
                await this.$router.push({name: routeName});
            },
            logout: async function () {
                await Vue.prototype.$store.dispatch('auth/setToken', {token: null});
                await this.$router.push({name: 'Login'});
            }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>